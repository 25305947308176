var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mt-10", attrs: { "no-gutters": "" } },
    [
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowed },
        on: { close: _vm.closeNotAllowed },
      }),
      _c(
        "v-row",
        { staticClass: "mx-10 px-10" },
        [
          _vm._l(_vm.Actions, function (item, n) {
            return [
              item.rolesAllowed.some(function (role) {
                return _vm.$store.getters.getRole.includes(role)
              })
                ? _c(
                    "v-col",
                    { key: n, staticClass: "pa-3 mx-4", attrs: { cols: "3" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var hover = ref.hover
                                return [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-auto my-3",
                                      attrs: {
                                        width: "200",
                                        height: "200px",
                                        "max-height": "200px",
                                        "max-width": "200px",
                                        elevation: hover ? 16 : 2,
                                        color: hover
                                          ? item.backgroundColor
                                          : "white",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.actions(item.action)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-center pt-6  ",
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-4 ",
                                              attrs: {
                                                color: item.backgroundColor,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    "background-color":
                                                      "primary lighten-2",
                                                    size: "30",
                                                    color: item.iconColor,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.icon) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-center text-wrap",
                                        },
                                        [
                                          _c("v-card-title", {
                                            staticStyle: {
                                              "{font-family": "Source Sans Pro",
                                              "font-size": "28px",
                                              "font-weight": "600",
                                              "line-height": "35px",
                                              "letter-spacing": "0em",
                                              "text-align": "center",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(item.name),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { key: n, staticClass: "pa-3", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto my-3",
                          attrs: {
                            color: "grey",
                            width: "200px",
                            height: "200px",
                            "max-height": "200px",
                            "max-width": "200px",
                          },
                          on: { click: _vm.redirectNotAllowed },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center pt-6" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-4",
                                  attrs: {
                                    "background-color": "grey lighten-2",
                                    size: "30",
                                    color: "black",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center text-wrap" },
                            [
                              _c("v-card-title", {
                                staticStyle: {
                                  "{font-family": "Source Sans Pro",
                                  "font-size": "28px",
                                  "font-weight": "600",
                                  "line-height": "35px",
                                  "letter-spacing": "0em",
                                  "text-align": "center",
                                },
                                domProps: { innerHTML: _vm._s(item.name) },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }